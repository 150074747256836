<template>
  <div>
    <div class="container-fluid">
      <div class="row page-titles">
        <div class="col-md-5 align-self-center">
          <h4 class="text-themecolor">{{$parent.pageTitle}}</h4>
        </div>
        <div class="col-md-7 align-self-center text-right">
          <div class="d-flex justify-content-end align-items-center">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link :to="{name:'Home'}">Home</router-link>
              </li>
              <li class="breadcrumb-item active">{{$parent.pageTitle}}</li>
            </ol>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
          <div class="">
            <div class="row">
              <div class="col-md-12">

                <ul class="nav nav-tabs customtab" role="tablist">
                  <li class="nav-item"> <a class="nav-link active" id="main-nav" data-toggle="tab" href="#editor" role="tab" @click="changeTab('main')"><span
                        class="hidden-sm-up"></span> <span class="hidden-xs-down">About Us</span></a> </li>
                  <li class="nav-item"> <a class="nav-link" id="seo-nav" data-toggle="tab" href="#seo" role="tab" @click="changeTab('SEO')"><span
                        class="hidden-sm-up"></span> <span class="hidden-xs-down">SEO Setting</span></a> </li>
                </ul>

                <div class="tab-content">
                  <!-- main -->
                  <div class="tab-pane fade show active" id="editor" role="tabpanel">
                    <!--card -->

                    <div class="card">
                      <div class="card-header">
                        <h5 class="card-title mb-0">Hero Image</h5>
                        <small>Last updated : {{(row_hero.ahi_update_date||"").dates("format")}}</small>
                      </div>
                      <div class="card-body">
                        <div class="row">
                          <div class="col-md-4">
                            <div class="wrap_slider_img">
                              <div class="bullet-cta">
                                <a href="javascript:;" @click="openHero()" class="bullet_edit" v-tooltip="'Edit Hero Image'"><i
                                    class="fas fa-pencil-alt"></i></a>
                              </div>
                              <img
                                :src="uploader((row_hero.ahi_background || '').removeWebp(),'411')"
                                class="img-responsive" />
                              <div class="slider_name">
                                <p># {{row_hero.ahi_title}}</p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <ValidationObserver v-if="isHero" v-slot="{ handleSubmit }" ref="VForm8">
                          <form v-on:submit.prevent="handleSubmit(doSubmitHero)">
                            <div class="row mt-4" id="collapseExample">
                              <div class="col-md-8 mb-3">
                                <div class="row">
                                  <div class="col-md-12">
                                    <div class="panel_group">
                                      <div class="panel_heading">Hero Image Text</div>
                                      <div class="panel_body">
                                        <div class="row">
                                          <div class="col-md-12">
                                            <div class="form-group">
                                              <label class="control-label">Title</label>
                                              <input v-model="row_hero.ahi_title" type="text" id="firstName"
                                                class="form-control" placeholder="Page Title">
                                              <VValidate name="Title" v-model="row_hero.ahi_title" rules="required" />

                                            </div>
                                          </div>
                                          <div class="col-md-12">
                                            <div class="form-group">
                                              <label class="control-label">Description</label>
                                              <textarea class="form-control" v-model="row_hero.ahi_description" rows="5"
                                                placeholder="Page Description"
                                                rules="required">
                                              </textarea>
                                              <VValidate name="Description" v-model="row_hero.ahi_description"
                                                rules="required" />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-4 mb-3">
                                <Uploader v-model="row_hero.ahi_background" type="hero" label="Hero Image" :block="true" />
                                <VValidate name="Image Hero" v-model="row_hero.ahi_background" rules="required" />
                                <div class="form-group mt-3">
                                  <label class="control-label">Alt Image <span class="text-danger">*</span></label>
                                  <input type="text" v-model="row_hero.ahi_alt" class="form-control" placeholder="Alt Image">
                                  <VValidate name="Alt Image Hero" v-model="row_hero.ahi_alt" rules="required" />
                                </div>
                              </div>
                            </div>
                            <div class="row d-flex justify-content-end">
                              <div class="col-4 mt-3">
                                <button type="submit" class="btn btn-rounded btn-block btn-info">Submit</button>
                              </div>
                            </div>
                          </form>
                        </ValidationObserver>
                      </div>
                    </div>
                    <!--card -->

                    <!-- Company overview-->
                    <div class="card">
                      <div class="card-header">
                        <h5 class="card-title mb-0">Company Overview</h5>
                        <small>Last updated : {{(row.sw_update_date||"").dates("format2")}}</small>
                      </div>

                      <ValidationObserver v-slot="{ handleSubmit }" ref="VForm2">
                        <form v-on:submit.prevent="handleSubmit(doSubmitAbout)">
                          <div class="card-body">
                            <div class="form-body">
                              <div class="row">
                                <div class="col-md-12">
                                  <div class="row">
                                    <div class="col-md-12">
                                      <div class="form-group">
                                        <label class="control-label">Title <span class="text-danger mr5">*</span>
                                        </label>
                                        <input v-model="row.sw_title" type="text" id="firstName" class="form-control"
                                          placeholder="Title">
                                        <VValidate name="About Title" v-model="row.sw_title" rules="required" />
                                      </div>
                                      <div class="form-group">
                                        <label class="control-label">Description <span
                                            class="text-danger mr5">*</span></label>
                                        <CKEditor 
                                          :value.sync="row.sw_description" 
                                        />
                                        <!-- <tinymce id="description" @editorInit="tmEditorInitCompany(row.sw_description, 'reff')"
                                          v-model="row.sw_description" ref="reff"
                                          toolbar1='formatselect | bold italic underline | numlist bullist outdent indent | link image | fontselect'
                                          :other_options='{images_upload_url:apiUrl+"/ajax/upload?pageType=editor",content_style: "body { font-size: .875rem; font-family: Arial;}",height : "200",menubar: false,branding:false}'>
                                        </tinymce> -->
                                        <VValidate name="About Desc." v-model="row.sw_description" rules="required" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                          </div>
                          <div class="card-footer text-right">
                            <div class="form-row justify-content-end">
                              <div class="col-md-2">
                                <button type="submit" class="btn btn-rounded btn-info btn-block">Submit</button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </ValidationObserver>
                    </div>

                    <!-- Vision Mision Values -->

                    <div class="card">
                      <div class="card-header">
                        <h5 class="card-title mb-0">Vision, Mission, Value</h5>
                        <small>Last updated : {{(row_VM[0].sw_update_date||"").dates("format2") || (row_VM[1].sw_update_date||"").dates("format2")}}</small>
                      </div>
                      <ValidationObserver v-slot="{ handleSubmit }" ref="VForm3">
                        <form v-on:submit.prevent="handleSubmit(doSubmitVM)">
                          <div class="card-body">
                            <div class="form-body">
                              <div class="row">
                                <div class="col-md-6">
                                  <div class="form-group mb-0">
                                    <label class="control-label">Vision <span class="text-danger mr5">*</span></label>
                                    <CKEditor 
                                      v-if="row_VM[0]"
                                      :value.sync="row_VM[0].sw_description" 
                                    />
                                    <!-- <tinymce id="vision" @editorInit="tmEditorInit(row_VM[0].sw_description)" 
                                    v-model="row_VM[0].sw_description"
                                      ref="tm"
                                      toolbar1='formatselect | bold italic underline | numlist bullist outdent indent | link image |'
                                      :other_options='{images_upload_url:apiUrl+"/ajax/upload?pageType=editor",content_style: "body { font-size: .875rem; font-family: Arial;}",height : "200",menubar: false,branding:false}'
                                      >
                                    </tinymce> -->
                                    <VValidate name="Vision" v-model="row_VM[0].sw_description" rules="required" />
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="form-group mb-0">
                                    <label class="control-label">Mision <span class="text-danger mr5">*</span></label>
                                    <CKEditor 
                                      v-if="row_VM[1]"
                                      :value.sync="row_VM[1].sw_description" 
                                    />
                                    <!-- <tinymce id="mision" @editorInit="tmEditorInit(row_VM[1].sw_description)" 
                                    v-model="row_VM[1].sw_description"
                                      ref="tm"
                                      toolbar1='formatselect | bold italic underline | numlist bullist outdent indent | link image |'
                                      :other_options='{images_upload_url:apiUrl+"/ajax/upload?pageType=editor",content_style: "body { font-size: .875rem; font-family: Arial;}",height : "200",menubar: false,branding:false}'
                                      >
                                    </tinymce> -->
                                    <VValidate name="Mision" v-model="row_VM[1].sw_description" rules="required" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          
                          <div class="card-footer">
                            <div class="form-row justify-content-end">
                              <div class="col-md-2">
                                <button type="submit" class="btn btn-rounded btn-block btn-info">Submit</button>
                              </div>
                            </div>
                          </div>
                          <div class="table-responsive mb-3">
                            <table class="table table-striped product-overview mb-0">
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th>Value Name</th>
                                  <th>Icon</th>
                                  <th>Status</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <draggable v-model="data_values.data" tag="tbody" v-if="(data_values.data||[]).length > 0" v-bind="dragOptions"
                                @end="endDrag2">
                                <tr :class="(data_values.data||[]).length > 1 ? 'pointer ' : '' " v-for="(v, i) in data_values.data" :key="i">
                                  <td>{{i + 1}}</td>
                                  <td><span v-tooltip="v.aov_update_date ? 'Last updated : ' + v.aov_update_date.dates('format2') : 'Created : ' + v.aov_create_date.dates('format2')">{{v.aov_title}}</span></td>
                                  <td>
                                    <div class="wrap_bulet_value">
                                      <img :src="apiUrl+'/uploader/' + (v.aov_logo || '').removeWebp()" class="img-responsive" />
                                    </div>
                                  </td>
                                  <td>
                                    <span v-if="v.aov_is_active == 'Y'" class="label label-success">Active</span>
                                    <span v-if="v.aov_is_active == 'N'" class="label label-danger">Inactive</span>
                                  </td>
                                  <td>
                                    <a href="javascript:;" class="text-inverse icon_action act_icon" v-tooltip="'Edit Content'"
                                      @click="setModalValue(i)"><i class="ti-marker-alt"></i></a>

                                    <a href="javascript:;" class="text-inverse icon_action act_icon" v-tooltip="'Change Status'"
                                      @click="doChangeStatusValue(i,v)"><i class="ti-settings"></i></a>

                                    <a href="javascript:;" class="text-inverse icon_action act_icon"
                                      v-tooltip="'Delete'" @click="doDeleteValues(i,v)">
                                      <i class="ti-trash"></i>
                                    </a>
                                  </td>
                                </tr>
                              </draggable>
                              <tr v-if="(data_values.data||[]).length === 0">
                                <td class="text-center" colspan="5">Data Not Available</td>
                              </tr>
                              <tr>
                                <td class="text-center" colspan="5">
                                  <button v-on:click="setModalValue()" type="button"
                                    class="btn btn-outline-info btn-rounded"><i class="fa fa-plus-circle mr-2"></i>
                                    Add New Value</button>
                                </td>
                              </tr>
                            </table>
                          </div>
                        </form>
                      </ValidationObserver>
                    </div>
                    <VModalVue :open.sync="isModalOpenValue" :inlineBackdrop="false" :title="'Detail Contact Form'">
                      <template v-slot:title>
                        <h4 class="m-0"> {{isAddValue ? 'Add' : 'Edit'}} Our Value</h4>
                      </template>

                      <template v-slot:body>
                        <ValidationObserver v-slot="{ handleSubmit }" ref="VForm4">
                          <form v-on:submit.prevent="handleSubmit(doSubmitVal)">
                            <div class="modal-body">
                              <div class="row">

                                <div class="col-md-12 mt-2">
                                  <div class="form-group">
                                    <Uploader v-model="dataModalValue.aov_logo" type="icon" label="Logo" />
                                    <VValidate name="Value Logo" v-model="dataModalValue.aov_logo" rules="required" />
                                
                                    <div class="form-group mt-2">
                                      <label class="control-label">Alt Image <span class="text-danger">*</span></label>
                                      <input type="text" v-model="dataModalValue.aov_alt" class="form-control" placeholder="Alt Image">
                                      <VValidate name="Alt Image Value" v-model="dataModalValue.aov_alt" rules="required" />
                                    </div>
                                  </div>
                                </div>

                                <div class="col-md-12 mt-2">
                                  <div class="form-group">
                                    <label class="control-label">Value Name<span class="text-danger mr5">*</span>
                                    </label>
                                    <input v-model="dataModalValue.aov_title" type="text" id="firstName"
                                      class="form-control" placeholder="Value Name">
                                    <VValidate name="Value Name" v-model="dataModalValue.aov_title" rules="required" />
                                  </div>
                                </div>


                                <div class="col-md-12 mt-2">
                                  <div class="form-group">
                                    <label class="control-label">Status <span class="text-danger mr5">*</span></label>
                                    <div class="row">
                                      <div class="col-md-12">
                                        <div class="d-flex">
                                          <div class="custom-control custom-radio" style="margin-right: 10px;">
                                            <input type="radio" name="radioRow" id="Yes" v-model="dataModalValue.aov_is_active" value="Y" class="custom-control-input" />
                                            <label for="Yes" class="custom-control-label">Active</label>
                                          </div>
                                          <div class="custom-control custom-radio">
                                            <input type="radio" name="radioRow" id="No" v-model="dataModalValue.aov_is_active" value="N" class="custom-control-input" />
                                            <label for="No" class="custom-control-label">Inactive</label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <VValidate name="Value Status" v-model="dataModalValue.aov_is_active"
                                      rules="required" />
                                  </div>
                                  <div class="modal-footer">
                                    <button type="submit" class="btn btn-rounded  btn-info">Submit</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </ValidationObserver>
                      </template>
                    </VModalVue>


                    <!-- Guarantee -->
                    <div class="card">
                      <div class="card-header">
                        <h5 class="card-title mb-0">The Illuminate Asia Guarantee</h5>
                        <small>Last updated : {{(row_guarantee.sw_update_date||"").dates("format2")}}</small>
                      </div>
                      <ValidationObserver v-slot="{ handleSubmit }" ref="VForm6">
                        <form v-on:submit.prevent="handleSubmit(doSubmitGuarantee)">
                          <div class="card-body">
                            <div class="form-body">
                              <div class="row">
                                <div class="col-md-8">
                                  <div class="row">
                                    <div class="col-md-12">
                                      <div class="form-group">
                                        <label class="control-label" for="guaranteeTitle">Guarantee Title <span
                                            class="text-danger mr5">*</span> </label>
                                        <textarea name="" id="guaaranteeTitle" cols="30"
                                          v-model="row_guarantee.sw_title" rows="4" class="form-control"
                                          placeholder="Guarantee Title"></textarea>
                                        <VValidate name="Guarantee Title" v-model="row_guarantee.sw_title"
                                          rules="required" />
                                      </div>
                                      <div class="form-group">
                                        <label class="control-label">Guarantee Description <span
                                            class="text-danger mr5">*</span></label>
                                        <textarea class="textarea_editor form-control" rows="8"
                                          v-model="row_guarantee.sw_description"
                                          placeholder="Guarantee Description"></textarea>
                                        <VValidate name="Guarantee Description" v-model="row_guarantee.sw_description"
                                          rules="required" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <label class="control-label">Guarantee Icon <span class="text-danger mr5">*</span>
                                  </label>
                                  <Uploader v-model="row_guarantee.sw_img" type="user" label="Photo" />
                                  <VValidate name="Image Guarantee" v-model="row_guarantee.sw_img" rules="required" />
                                  <div class="form-group mt-2">
                                    <label class="control-label">Alt Image <span class="text-danger">*</span></label>

                                    <input type="text" v-model="row_guarantee.sw_alt" class="form-control" placeholder="Alt Image">
                                    <VValidate name="Alt Image Guarantee" v-model="row_guarantee.sw_alt" rules="required" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="card-footer">
                            <div class="form-row justify-content-end">
                              <div class="col-md-2">
                                <button type="submit" class="btn btn-rounded btn-block btn-info">Submit</button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </ValidationObserver>
                    </div>

                    <!-- Teams -->
                    <div class="card" id="card-team">
                      <div class="card-header">
                        <div class="d-flex align-items-center justify-content-between">
                          <h5 class="card-title">Our Team</h5>
                          <button @click="setModalTeams()" type="button" class="btn btn-info btn-rounded"><i
                              class="fa fa-plus-circle mr-2"></i>
                            Add New Team</button>
                        </div>
                      </div>
                      <div v-if="data_teams.data" class="card-body">
                        <div class="form-body">
                          <draggable tag="div" class="row gutter-20 justify-content-center" v-model="data_teams.data" v-if="data_teams.data.length > 0" v-bind="dragOptions"
                            @end="endDrag">
                            <div class="col-md-3" v-for="(v, k) in data_teams.data" :key="k">
                              <div :class="data_teams.data.length > 1 ? 'pointer wrap_article' : 'wrap_article'">
                                <div class="wrap_thumb" v-tooltip=" v.at_update_date ? 'Last updated : ' + v.at_update_date.dates('format2') : 'Created : ' + v.at_create_date.dates('format2')">
                                  <img
                                    :src="v.at_img === '' ? baseUrl+'/img/user-default.png' : apiUrl + '/uploader/' + (v.at_img || '').removeWebp()"
                                    class="img-responsive" />

                                  <div class="bullet-cta">
                                    <a href="javascript:;" v-tooltip="'Edit Content'" @click="setModalTeams(k)" class="bullet_edit mr-1"><i
                                        class="fas fa-pencil-alt"></i></a>

                                    <a href="javascript:;" v-tooltip="'Change Status'" @click="doChangeStatusTeam(k,v)"
                                      role="button" class="bullet_edit mr-1"><i class="fas fa-cog"></i></a>

                                    <a href="javascript:;" v-tooltip="'Delete'" @click="doDeleteTeam(k,v, data_teams.data)"
                                      class="bullet_delete"><i class="far fa-trash-alt"></i></a>
                                  </div>
                                </div>
                                <div class="wrap_content_post text-center">
                                  <span v-if="v.at_is_active == 'Y' " class="label label-success">Active</span>
                                  <span v-if="v.at_is_active == 'N' " class="label label-danger">Inactive</span>
                                  <h6>{{v.at_position}}</h6>
                                  <h2>{{v.at_name}}</h2>
                                  <ul>
                                    <li>{{v.at_email}}</li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </draggable>
                          <div class="row d-flex justify-content-center">
                            <div class="col-md-3" v-if="(data_teams.data || []).length === 0">
                              <span>Data Not Available</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card-footer" v-if="data_teams.data!==false&& (data_teams.data||[]).length !== 0 ">
                        <div class="row">
                          <div class="col-sm-6"></div>
                          <div class="col-sm-6">
                            <div class="pagination-flat float-right">
                              <Pagination :data="data_teams" :limit="2" @pagination-change-page="doPaginateTeam">
                                <span slot="prev-nav"><i class="icon-arrow-left"></i></span>
                                <span slot="next-nav"><i class="icon-arrow-right"></i></span>
                              </Pagination>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Modal Teams -->
                    <VModalVue :open.sync="isModalOpenTeams" :size="'modal-lg'" :inlineBackdrop="false"
                      :title="'Detail Team Form'">
                      <template v-slot:title>
                        <h4>{{isAddTeams ? 'Add' : 'Edit'}} Our Team</h4>
                      </template>

                      <template v-slot:body>
                        <ValidationObserver v-slot="{ handleSubmit }" ref="VForm5">
                          <form v-on:submit.prevent="handleSubmit(doSubmitTeams)">
                            <div class="modal-body">
                              <div class="row">
                                <div class="col-md-6 col-lg-5">
                                  <label class="control-label">Team Photo<span class="text-danger mr5">*</span>
                                  </label>
                                  <Uploader v-model="dataModalTeams.at_img" type="team" label="photo" />
                                  <VValidate name="Team Photo" v-model="dataModalTeams.at_img" rules="required" />

                                  <div class="form-group mt-2">
                                    <label class="control-label">Alt Image <span class="text-danger">*</span></label>
                                    <input type="text" v-model="dataModalTeams.at_alt" class="form-control" placeholder="Alt Image">
                                    <VValidate name="Alt Guatrantee Img" v-model="dataModalTeams.at_alt" rules="required" />
                                  </div>

                                </div>

                                <div class="col-md-6 col-lg-7">
                                  <div class="form-group">
                                    <label class="control-label">Full Name<span class="text-danger mr5">*</span>
                                    </label>
                                    <input type="text" v-model="dataModalTeams.at_name" id="firstName"
                                      class="form-control" placeholder="Full Name">
                                    <VValidate name="Full Name" v-model="dataModalTeams.at_name" rules="required|min:3" />
                                  </div>
                                  <div class="form-group">
                                    <label class="control-label">Title<span class="text-danger mr5">*</span>
                                    </label>
                                    <input type="text" v-model="dataModalTeams.at_position" id="firstName"
                                      class="form-control" placeholder="Title">
                                    <VValidate name="Team Position" v-model="dataModalTeams.at_position"
                                      rules="required" />
                                  </div>
                                  <div class="form-group">
                                    <label class="control-label">Email<span class="text-danger mr5">*</span>
                                    </label>
                                    <input type="email" v-model="dataModalTeams.at_email" id="firstName"
                                      class="form-control" placeholder="Email">
                                    <VValidate name="Email Address" v-model="dataModalTeams.at_email"
                                      rules="required|email" />
                                  </div>
                                  <label class="control-label">Status<span class="text-danger mr5">*</span></label>
                                  <div class="row">
                                    <div class="col-md-12">
                                      <!-- <InputRadio v-model="dataModalTeams.at_is_active" name="status" option="Y" />
                                      <InputRadio v-model="dataModalTeams.at_is_active" name="status" option="N" /> -->
                                      <div class="d-flex">
                                        <div class="custom-control custom-radio" style="margin-right: 10px;">
                                          <input type="radio" name="radioRow" id="Yes" v-model="dataModalTeams.at_is_active" value="Y" class="custom-control-input" />
                                          <label for="Yes" class="custom-control-label">Active</label>
                                        </div>
                                        <div class="custom-control custom-radio">
                                          <input type="radio" name="radioRow" id="No" v-model="dataModalTeams.at_is_active" value="N" class="custom-control-input" />
                                          <label for="No" class="custom-control-label">Inactive</label>
                                        </div>
                                      </div>
                                      <br />
                                      <VValidate name="Status Team" v-model="dataModalTeams.at_is_active"
                                        rules="required" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row mt-3">
                                <div class="col-md-12">
                                  <label class="control-label">Bio
                                  </label>
                                  <CKEditor 
                                    :value.sync="dataModalTeams.at_bio" 
                                  />
                                  <!-- <tinymce id="bio" @editorInit="tmEditorInit(dataModalTeams.at_bio)" 
                                    v-model="dataModalTeams.at_bio"
                                      ref="tm"
                                      toolbar1='formatselect | bold italic underline | numlist bullist outdent indent | link image |'
                                      :other_options='{images_upload_url:apiUrl+"/ajax/upload?pageType=editor",content_style: "body { font-size: .875rem; font-family: Arial;}",height : "400",menubar: false}'
                                    >
                                  </tinymce> -->
                                </div>
                              </div>
                            </div>
                            <div class="modal-footer">
                              <button type="submit" class="btn btn-rounded  btn-info">Submit</button>
                            </div>
                          </form>
                        </ValidationObserver>
                      </template>
                    </VModalVue>

                    <!-- Coverage -->
                    <VModalVue :open.sync="isModalOpenCV" :inlineBackdrop="false" :title="'Detail Coverage Form'">
                      <template v-slot:title>
                        <h4>{{isAddCV ? 'Add' : 'Edit'}} Coverage</h4>
                      </template>

                      <template v-slot:body>
                        <ValidationObserver v-slot="{ handleSubmit }" ref="VForm7">
                          <form v-on:submit.prevent="handleSubmit(doSubmitCV)">
                            <div class="modal-body">
                              <div class="col-md-12 mt-2" v-if="!isAddCV">
                                <div class="form-group">
                                  <label class="control-label text-primary" >Last updated : 
                                  {{(dataModalCV.ac_update_date||"").dates("format2")}}</label>
                                </div>
                              </div>
                              <div class="col-md-12 mt-2">
                                <div class="form-group">
                                  <label class="control-label">Country<span class="text-danger mr5">*</span>
                                  </label>
                                  <input type="text" v-model="dataModalCV.ac_name" id="firstName" class="form-control"
                                    placeholder="Country">
                                  <VValidate name="City Name" v-model="dataModalCV.ac_name" rules="required" />
                                </div>
                              </div>
                              <div class="col-md-12 mt-2">
                                <div class="form-group">
                                  <label class="control-label">Cover All City<span class="text-danger mr5"></span>
                                  </label>
                                  <div class="row">
                                    <div class="col-md-2">
                                        <div class="custom-control custom-radio">
                                        <input type="radio" id="customRadio1" v-model="coverCity" value="Y" option="Y" name="radioMaps" class="custom-control-input">
                                        <label class="custom-control-label" for="customRadio1">Yes</label>
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="custom-control custom-radio">
                                        <input type="radio" id="customRadio2" v-model="coverCity" value="N" option="N" name="radioMaps" class="custom-control-input">
                                        <label class="custom-control-label" for="customRadio2">No</label>
                                        </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-12 mt-2" v-if="coverCity === 'N'">
                                <div class="form-group">
                                  <label class="control-label">City<span class="text-danger mr5">*</span>
                                  </label>
                                  <sub>Type and press enter to add</sub><br>
                                  <vSelect multiple class="vs__multiple" v-model="dataModalCV.ac_address"
                                    placeholder="City" :taggable="true" ref="select">
                                    <span class="d-none" slot="no-options" @click="$refs.select.open = false"></span>
                                  </vSelect>
                                  <VValidate name="City " v-model="dataModalCV.ac_address" rules="required" />
                                </div>
                              </div>
                            </div>
                            <div class="modal-footer">
                              <button v-if="!isAddCV" type="button" @click="doDeleteCV(dataModalCV.ac_id)"
                                class="btn btn-rounded btn-outline-custom">Delete</button>
                              <button type="submit" class="btn btn-rounded  btn-info">Submit</button>
                            </div>
                          </form>
                        </ValidationObserver>
                      </template>
                    </VModalVue>


                    <div class="card">
                      <div class="card-header">
                        <h5 class="card-title mb-0">Our Coverage - World </h5>
                      </div>
                      <div class="card-body">
                        <form action="#">
                          <div class="form-body" id="addCity">
                            <div class="row d-flex justify-content-center">
                              <div class="col-md-8">
                                <div class="map_indo">
                                  <img :src="baseUrl+'/img/coverage-map.svg'" />

                                  <a v-for="(v, k) in data_CV.data" :key="k" class="point_map a1" @click="setModalCV(k)" v-tooltip="v.ac_name + ' (' + v.ac_address + ')'" href="javascript:;" :style="'top:'+ v.ac_top + '%;'+ 'left:'+ v.ac_left +'%;'"></a>

                                </div>
                              </div>
                            </div>
                            <div v-if="clickMap" class="row justify-content-center mt-3 cta-action">
                              <div class="col-md-3">
                                <button @click="clickcity()" type="button" class="btn btn-info btn-rounded btn-block"><i
                                    class="fa fa-plus-circle mr-2"></i>
                                  Add New City</button>
                              </div>
                            </div>
                            <div v-if="!clickMap" class="row justify-content-center mt-3 cta-action">
                              <div class="col-12 text-center">
                                <p>Click on the map to add a pinpoint</p>
                              </div>
                              <div class="col-md-3">
                                <button type="button" @click="closedcity()"
                                  class="btn btn-danger btn-rounded btn-block"><i class="fa fa-times-circle mr-2"></i>
                                  Cancel</button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <!-- seo -->
                  <div class="tab-pane fade" id="seo" role="tabpanel">
                    <div class="card" v-if="isMeta">
                      <ValidationObserver v-slot="{ handleSubmit }" ref="VFormSeo">
                        <form v-on:submit.prevent="handleSubmit(doSubmitSeo)">
                          <div class="card-body">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="form-group">
                                  <label class="control-label">Meta Title <a
                                      v-tooltip="'This field functions to ease Google to understand the title of this website page. Tips: Make sure the characters length is less than 70 chars.'"
                                      class="help_info">?</a> <span class="text-danger mr5">*</span></label>
                                  <input v-model="rowSEO.sss_title" type="text" id="firstName" class="form-control"
                                    placeholder="Meta Title"  @input="checkDisabled">
                                  <VValidate name="Meta Title" :rules="{required: 1}" v-model="rowSEO.sss_title"/>
                                </div>
                              </div>
                              <div class="col-md-12">
                                <div class="form-group">
                                  <label for="" class="control-label">
                                    Meta Keyword
                                    <a class="help_info"
                                      v-tooltip="'It can be used to give more information to search engines on what a page is about. The keywords are generally written in lowercase and separated with a comma.'">
                                      ?
                                    </a>
                                    <span class="text-danger mr5">*</span>
                                  </label>
                                  <input-tag v-model="rowSEO.sss_keyword" @input="checkDisabled"></input-tag>

                                  <VValidate name="Keyword" v-model="rowSEO.sss_keyword" rules="required" />
                                </div>
                              </div>
                              <div class="col-md-12">
                                <div class="form-group">
                                  <label class="control-label">Meta Description <a
                                      v-tooltip="'A meta description is an element that contains a short summary of the page and generates the brief snippet we see underneath a site`s title in Google`s search results.'"
                                      class="help_info">?</a> <span class="text-danger mr5">*</span></label>
                                  <textarea v-model="rowSEO.sss_desc" class="form-control" rows="4" @input="checkDisabled"></textarea>

                                  <VValidate name="Meta Description" v-model="rowSEO.sss_desc" :rules="{required: 1}" />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="card-footer">
                            <div class="form-actions text-right">
                              <button type="button" @click.prevent="doReset()" disabled
                                class="btn  btn-rounded  btn-secondary" id="button-cancel">Cancel
                              </button>
                              <button type="submit" class="btn  btn-rounded  btn-info">Submit</button>
                            </div>
                          </div>
                        </form>
                      </ValidationObserver>
                    </div>
                    <div class="card" v-else>
                      <div class="card-body">
                        <div class="row">
                          <div class="col-md-12 text-right">
                            <label class="text-primary">Last updated : {{(rowSEO.sss_update_date||"").dates('format2')}}</label>
                          </div>
                          <div class="col-md-12">
                            <div class="form-group">
                              <label class="control-label">Meta Title <a
                                  v-tooltip="'This field functions to ease Google to understand the title of this website page. Tips: Make sure the characters length is less than 70 chars.'"
                                  class="help_info">?</a> <span class="text-danger mr5">*</span></label>
                                  <p>{{rowSEO.sss_title}}</p>
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div class="form-group">
                              <label for="" class="control-label">
                                Meta Keyword
                                <a class="help_info"
                                  v-tooltip="'It can be used to give more information to search engines on what a page is about. The keywords are generally written in lowercase and separated with a comma.'">
                                  ?
                                </a>
                                <span class="text-danger mr5">*</span>
                              </label>
                              <div></div>
                              <template v-for="(v,k) in rowSEO.sss_keyword" >
                                <label class="label label-success mr-1" :key="k">
                                  {{v}}
                                </label>
                              </template>
                              
                              <!-- <p>{{rowSEO.keyword_text}}</p> -->
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div class="form-group">
                              <label class="control-label">Meta Description <a
                                  v-tooltip="'A meta description is an element that contains a short summary of the page and generates the brief snippet we see underneath a site`s title in Google`s search results.'"
                                  class="help_info">?</a> <span class="text-danger mr5">*</span></label>
                              <p>{{rowSEO.sss_desc}}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card-footer">
                        <div class="form-actions text-right">
                          <a href="javascript:;" @click="editMeta()" class="btn btn-rounded btn-info">Edit</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

button:disabled{
  cursor: auto;
}
.ck.ck-balloon-panel {
  z-index: 99999999999 !important
}
.cke_dialog
{
  z-index: 100551111 !important;
}

</style>

<script>
  import GlobalVue from '@libs/Global'
  import Gen from '@libs/Gen.js'
  import VModalVue from "../components/VModal.vue"
  import draggable from 'vuedraggable'
  import CKEditor from '@/components/CKEditor'

  let $ = global.jQuery
  let _ = global._

  export default {
    extends: GlobalVue,
    components: {
      VModalVue,
      draggable,
      CKEditor
    },
    data() {
      return {
        idKey: '',
        isHero: false,
        isMeta: false,
        row_hero: {},
        rowSEO: {},
        rowSEOtemp: {},
        row_VM: [{
            sw_description: ""
          },
          {
            sw_description: ""
          },
        ],
        data_values: [],
        dataModalValue: {
          aov_logo: "",
        },
        isModalOpenValue: false,
        isAddValue: true,

        data_teams: [],
        dataModalTeams: {
          at_img: ""
        },
        isModalOpenTeams: false,
        isAddTeams: true,

        row_guarantee: {},

        clickMap: true,
        coverCity : "Y",
        data_CV: [],
        isModalOpenCV: false,
        isAddCV: true,
        dataModalCV: {
          ac_img: ""
        },
        leftPercent: "",
        topPercent: "",
        tab: "",
        TabSeo : JSON.parse(localStorage.getItem("SEO"))
      }
    },
    methods: {
      
      editMeta(){
        this.isMeta = true
      },
      openHero() {
        this.isHero = !this.isHero;
      },
      tmEditorInitCompany(val, reff = null){
        if(reff === 'reff'){
          this.$refs.reff.editor.setContent(val);
          return;
        }
      },
      clickcity() {
        document.querySelector("#addCity .map_indo").style.cursor = 'pointer';
        var self = this;
        $("#addCity").toggleClass("add-city");
        self.clickMap = false;
        if (self.clickMap == false) {
          $("#addCity.add-city .map_indo").click(function () {
            if (!self.clickMap) {
              var x = event.pageX - $(this).offset().left;
              var y = event.pageY - $(this).offset().top;
              var leftPercent = x / $(this).width() * 97.2;
              var topPercent = y / $(this).height() * 96;

              self.leftPercent = leftPercent.toFixed(5);
              self.topPercent = topPercent.toFixed(5);

              $(".NMpas").remove();
              $(this).append("<a class='point_map a1 NMpas' style='top: " + topPercent + "%;  left: " +
                leftPercent + "%;'>");
              
              setTimeout(() => {
                self.setModalCV()
              }, 250);
            }
          });
        }
      },
      closedcity() {
        this.clickMap = true;
        document.querySelector("#addCity .map_indo").style.cursor = 'default';
        $(".NMpas").remove();
      },
      getPointClick() {
        $(document).ready(function () {
          $(".point_map").click(function () {
            $("#view_city").modal();
          });
        });
      },
      apiGetHeroImage: function () {
        if (this.pageSlug) this.loadingOverlay = true

        this.data.data = false
        Gen.apiRest(
          "/get/" + "HeroImageAbout"
        ).
        then(res => {
            this.loadingOverlay = false
            this.row_hero = res.data.row_hero;
          })
          .catch(() => {
            this.loadingOverlay = false
          })
      },
      doSubmitHero(IDFormHero = 'VForm8') {
        this.row_hero.ahi_update_by = this.user.id;
        return this.doSubmit(
          "/do/" + this.modulePage,
          _.assign({
            type: 'updateHeroImage',
            id: this.row_hero.ahi_id
          }, this.row_hero),
          (type, resp) => {
            if (type == 'success') {
              this.apiGetHeroImage();
              this.isHero = false;
            } else {
              if (resp.response.status == 422) {
                if ((_.values(resp.response.data)[0][0] || "").indexOf('validation') > -1) {
                  var msg = ""
                  $.each(Object.keys(resp.response.data), (i, v) => {
                    msg = msg + " <br/>" + v + " : " + _.values(resp.response.data[v]);
                  })
                  return global.Swal.fire("Blocked With Validation!", msg)
                } else {
                  return global.Swal.fire("Sorry, There is something wrong!", _.values(resp.response.data)[0][0])
                }
              } else if (resp.response.status == 400) {
                return global.Swal.fire({
                  title: resp.response.data.message,
                  icon: 'error',
                  confirmButtonText: 'Ok'
                })
              }
            }
          },
          'POST', IDFormHero
        )
      },
      doSubmitAbout(IdFormAbout = 'VForm2') {
        this.row.sw_update_by = this.user.id;
        return this.doSubmit(
          "/do/" + this.modulePage,
          _.assign({
            type: 'update',
            id: this.row.sw_id
          }, this.row),
          (type, resp) => {
            if (type == 'success') {
              this.apiGet();
            } else {
              if (resp.response.status == 422) {
                if ((_.values(resp.response.data)[0][0] || "").indexOf('validation') > -1) {
                  var msg = ""
                  $.each(Object.keys(resp.response.data), (i, v) => {
                    msg = msg + " <br/>" + v + " : " + _.values(resp.response.data[v]);
                  })
                  return global.Swal.fire("Blocked With Validation!", msg)
                } else {
                  return global.Swal.fire("Sorry, There is something wrong!", _.values(resp.response.data)[0][0])
                }
              } else if (resp.response.status == 400) {
                return global.Swal.fire({
                  title: resp.response.data.message,
                  icon: 'error',
                  confirmButtonText: 'Ok'
                })
              }
            }
          },
          'POST', IdFormAbout
        )
      },
      doDeleteImg() {
        this.row_hero.ahi_background = '';
      },

      // SEO   
      apiGetSEO() {
        if (this.pageSlug) this.loadingOverlay = true

        this.data.data = false
        Gen.apiRest(
          "/get/" + "SEOAbout"
        ).
        then(res => {
            this.loadingOverlay = false
            this.rowSEOtemp = res.data.row_seo;
            this.rowSEO = res.data.row_seo;
          })
          .catch(() => {
            this.loadingOverlay = false
          })
      },

      doBackSuccessMeta(){
        this.tab = "main"
        $("#seo").removeClass("active").removeClass("show");
        $("#editor").addClass("active").addClass("show");

        $("#seo-nav").removeClass("active");
        $("#main-nav").addClass("active");

        let confSeo = JSON.stringify({ menu : this.$route.name, val : "N"})
        localStorage.setItem("SEO", confSeo)
      },
      
      doSubmitSeo(IdFormSeo = 'VFormSeo') {
        var d = new Date();
        let dformat = [d.getMonth()+1,
                      d.getDate(),
                      d.getFullYear()].join('-')+' '+
                      [d.getHours(),
                      d.getMinutes(),
                      d.getSeconds()].join(':');
        this.rowSEO.sss_update_date = dformat;
        this.rowSEO.sss_update_by = this.user.id;
        return this.doSubmit(
          "/do/" + this.modulePage,
          _.assign({
            type: 'updateSeoAbout',
            id: this.rowSEO.sss_id
          }, this.rowSEO),
          (type, resp) => {
            if (type == 'success') {
              this.isMeta = false
              this.doBackSuccessMeta()
              this.apiGetSEO();
            } else {
              if (resp.response.status == 422) {
                if ((_.values(resp.response.data)[0][0] || "").indexOf('validation') > -1) {
                  var msg = ""
                  $.each(Object.keys(resp.response.data), (i, v) => {
                    msg = msg + " <br/>" + v + " : " + _.values(resp.response.data[v]);
                  })
                  return global.Swal.fire("Blocked With Validation!", msg)
                } else {
                  return global.Swal.fire("Sorry, There is something wrong!", _.values(resp.response.data)[0][0])
                }
              } else if (resp.response.status == 400) {
                return global.Swal.fire({
                  title: resp.response.data.message,
                  icon: 'error',
                  confirmButtonText: 'Ok'
                })
              }
            }
          },
          'POST', IdFormSeo
        )
      },
      // SEO END

      // VISION
      apiGetVisMis: function () {

        if (this.pageSlug) this.loadingOverlay = true
        this.data.data = false
        Gen.apiRest(
          "/get/" + "VisionMision"
        ).
        then(res => {
            this.loadingOverlay = false;
            setTimeout (()=>{
              this.row_VM = res.data.data;
            },100)
          })
          .catch(() => {
            this.loadingOverlay = false
          })
      },
      doSubmitVM(IDFormVis = 'VForm3') {
        return this.doSubmit(
          "/do/" + this.modulePage,
          _.assign({
            type: 'updateVM',
            iduser: this.user.id,
            idvision: this.row_VM[0].sw_id,
            vision: this.row_VM[0].sw_description,
            idmision: this.row_VM[1].sw_id,
            mision: this.row_VM[1].sw_description
          }),

          (type, resp) => {
            if (type == 'success') {
              this.apiGetVisMis()
            } else {
              if (resp.response.status == 422) {
                if ((_.values(resp.response.data)[0][0] || "").indexOf('validation') > -1) {
                  var msg = ""
                  $.each(Object.keys(resp.response.data), (i, v) => {
                    msg = msg + " <br/>" + v + " : " + _.values(resp.response.data[v]);
                  })
                  return global.Swal.fire("Blocked With Validation!", msg)
                } else {
                  return global.Swal.fire("Sorry, There is something wrong!", _.values(resp.response.data)[0][0])
                }
              } else if (resp.response.status == 400) {
                return global.Swal.fire({
                  title: resp.response.data.message,
                  icon: 'error',
                  confirmButtonText: 'Ok'
                })
              }
            }
          },
          'POST', IDFormVis
        )
      },
      // VISION END

      // VALUE
      setModalValue(i) {
        this.isModalOpenValue = true
        if (i == undefined) {
          this.isAddValue = true;
          this.dataModalValue = {};
          this.dataModalValue["aov_is_active"] = "Y";
        } else {
          this.isAddValue = false;
          this.loadingOverlay = true;

          Gen.apiRest(
            "/get/" + "OurValues/" + this.data_values.data[i].aov_id
          ).
          then(res => {
              this.loadingOverlay = false
              this.dataModalValue = res.data.row_val;
            })
            .catch(() => {
              this.loadingOverlay = false
            })
        }
      },
      apiGetOurValues: function () {
        if (this.pageSlug) this.loadingOverlay = true
        this.data.data = false
        Gen.apiRest(
          "/get/" + "OurValues"
        ).
        then(res => {
            this.loadingOverlay = false
            this.data_values = res.data.data;
          })
          .catch(() => {
            this.loadingOverlay = false
          })
      },
      doDeleteValues(k, v) {
        let id = v["aov_id"]
        global.Swal.fire({
          title: 'Are you sure to delete?',
          text: "You won't be able to restore this data!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonColor: '#3085d6',
          confirmButtonText: 'Yes, delete it!',
          customClass: {
            confirmButton: 'btn btn-custom',
            cancelButton: 'btn btn-outline-custom ml-1',
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            this.loadingOverlay = true
            this.openForm = false;
            Gen.apiRest("/do/" + 'OurValues', {
                data: {
                  type: 'delete',
                  id: id
                }
              }, "POST")
              .then(() => {
                this.apiGetOurValues();
                global.Swal.fire({
                  title: 'Value deleted Successfully!',
                  icon: 'success',
                })
              })
              .catch((err) => {
                this.loadingOverlay = false
                if (err) {
                  err.statusType = err.status;
                  err.status = "error";
                  err.message = err.response.data.message;
                  err.messageError = err.message
                }
                this.doSetAlertForm(err);
              })
          }
        })
      },
      doSubmitVal(IDFormVal = 'VForm4') {
      
        if (!this.isAddValue) {
          this.dataModalValue.aov_update_by = this.user.id;
        } else {
          this.dataModalValue.aov_create_by = this.user.id;
        }

        return this.doSubmit(
          "/do/" + "OurValues",
          _.assign({
            type: this.isAddValue ? 'add' : 'update'
          }, {
            id: this.dataModalValue.aov_id
          }, _.clone(this.dataModalValue)),
          (type, resp) => {
            if (type == 'success') {
              this.apiGetOurValues()
              this.isModalOpenValue = false
            } else {
              if (resp.response.status == 422) {
                if ((_.values(resp.response.data)[0][0] || "").indexOf('validation') > -1) {
                  var msg = ""
                  $.each(Object.keys(resp.response.data), (i, v) => {
                    msg = msg + " <br/>" + v + " : " + _.values(resp.response.data[v]);
                  })
                  return global.Swal.fire("Blocked With Validation!", msg)
                } else {
                  return global.Swal.fire("Sorry, There is something wrong!", _.values(resp.response.data)[0][0])
                }
              } else if (resp.response.status == 400) {
                return global.Swal.fire({
                  title: resp.response.data.message,
                  icon: 'error',
                  confirmButtonText: 'Ok'
                })
              }
            }
          },
          'POST', IDFormVal, this.isAddValue
        )
      },

      doChangeStatusValue(k, v) {
        let id = v["aov_id"];
        let changeStatus = v["aov_is_active"] == "Y" ? "N" : "Y"
        global.Swal.fire({
          title: `Change status to ${changeStatus=='N' ? 'Inactive' : 'Active'}?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: changeStatus == "N" ? '#f7ab38' : '#30d64c',
          cancelButtonColor: '#3085d6',
          confirmButtonText: `Yes, change to ${changeStatus=='N'? 'Inactive' : 'Active'}!`,
          customClass: {
            confirmButton: 'btn btn-custom',
            cancelButton: 'btn btn-outline-custom ml-1',
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            this.loadingOverlay = true

            Gen.apiRest(
                "/do/" + "OurValues", {
                  data: {
                    type: 'status',
                    id: id,
                    status: changeStatus
                  }
                },
                "POST"
              )
              .then(() => {
                this.loadingOverlay = false
                v["aov_is_active"] = changeStatus

                global.Swal.fire({
                  title: `Status changed to ${changeStatus=='Y'? 'Active' : 'Inactive'}.`,
                  icon: 'success',
                })
              })
              .catch((err) => {
                this.loadingOverlay = false
                if (err) {
                  err.statusType = err.status;
                  err.status = "error";
                  err.message = err.response.data.message;
                  err.messageError = err.message
                }
                this.doSetAlertForm(err);
              })
          }
        })
      },
      // VALUE END

      // TEAM
      setModalTeams(i) {
        this.isModalOpenTeams = true

        setTimeout(()=>{
          $(".ck.ck-balloon-panel").css({"z-index": "99999"});
        },1000)

        if (i == undefined) {
          this.isAddTeams = true;
          this.dataModalTeams = {};
          this.dataModalTeams["at_is_active"] = "Y";
        } else {
          this.isAddTeams = false;
          this.loadingOverlay = true;

          Gen.apiRest(
            "/get/" + "OurTeams/" + this.data_teams.data[i].at_id
          ).
          then(res => {
              this.loadingOverlay = false
              this.dataModalTeams = res.data.row_teams;
            })
            .catch(() => {
              this.loadingOverlay = false
            })
        }
      },

      apiGetOurTeams: function () {
        if (this.pageSlug) this.loadingOverlay = true
        this.data.data = false
        Gen.apiRest(
          "/get/" + "About"
        ).
        then(res => {
            this.loadingOverlay = false
            this.data_teams = res.data.data_teams;
          })
          .catch(() => {
            this.loadingOverlay = false
          })
      },

      doDeleteTeam(k, v, teams) {
        let id = v["at_id"]
        global.Swal.fire({
          title: 'Are you sure to delete?',
          text: "You won't be able to restore this data!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonColor: '#3085d6',
          confirmButtonText: 'Yes, delete it!',
          customClass: {
            confirmButton: 'btn btn-custom',
            cancelButton: 'btn btn-outline-custom ml-1',
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            this.loadingOverlay = true
            this.openForm = false;
            Gen.apiRest("/do/" + 'OurTeams', {
                data: {
                  type: 'delete',
                  id: id,
                  data_teams: teams
                }
              }, "POST")
              .then(() => {
                this.apiGetOurTeams();
                global.Swal.fire({
                  title: 'Team Deleted Successfully!',
                  icon: 'success',
                })
              })
              .catch((err) => {
                this.loadingOverlay = false
                if (err) {
                  err.statusType = err.status;
                  err.status = "error";
                  err.message = err.response.data.message;
                  err.messageError = err.message
                }
                this.doSetAlertForm(err);
              })
          }
        })
      },

      doSubmitTeams(IDFormTeams = 'VForm5') {

        if (!this.isAddTeams) {
          this.dataModalTeams.at_update_by = this.user.id;
        } else {
          if (this.data_teams.data.length == 0) this.dataModalTeams.at_sort = 1;
          this.dataModalTeams.at_create_by = this.user.id;
        }

        return this.doSubmit(
          "/do/" + "OurTeams",
          _.assign({
            type: this.isAddTeams ? 'add' : 'update'
          }, {
            id: this.dataModalTeams.at_id
          }, _.clone(this.dataModalTeams)),
          (type, resp) => {
            if (type == 'success') {
              this.apiGetOurTeams()
              this.isModalOpenTeams = false
            } else {
              if (resp.response.status == 422) {
                if ((_.values(resp.response.data)[0][0] || "").indexOf('validation') > -1) {
                  var msg = ""
                  $.each(Object.keys(resp.response.data), (i, v) => {
                    msg = msg + " <br/>" + v + " : " + _.values(resp.response.data[v]);
                  })
                  return global.Swal.fire("Blocked With Validation!", msg)
                } else {
                  return global.Swal.fire("Sorry, There is something wrong!", _.values(resp.response.data)[0][0])
                }
              } else if (resp.response.status == 400) {
                return global.Swal.fire({
                  title: resp.response.data.message,
                  icon: 'error',
                  confirmButtonText: 'Ok'
                })
              }
            }
          },
          'POST', IDFormTeams, this.isAddTeams
        )
      },

      doChangeStatusTeam(k, v) {
        let id = v["at_id"]
        let changeStatus = v["at_is_active"] == "Y" ? "N" : "Y"
        global.Swal.fire({
          title: `Change status to ${changeStatus=='N' ? 'Inactive' : 'Active'}?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: changeStatus == "N" ? '#f7ab38' : '#30d64c',
          cancelButtonColor: '#3085d6',
          confirmButtonText: `Yes, change to ${changeStatus=='N'? 'Inactive' : 'Active'}!`,
          customClass: {
            confirmButton: 'btn btn-custom',
            cancelButton: 'btn btn-outline-custom ml-1',
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            this.loadingOverlay = true

            Gen.apiRest(
                "/do/" + "OurTeams", {
                  data: {
                    type: 'status',
                    id: id,
                    status: changeStatus
                  }
                },
                "POST"
              )
              .then(() => {
                this.loadingOverlay = false
                v["at_is_active"] = changeStatus

                global.Swal.fire({
                  title: `Status changed to ${changeStatus=='Y'? 'Active' : 'Inactive'}.`,
                  icon: 'success',
                })
              })
              .catch((err) => {
                this.loadingOverlay = false
                if (err) {
                  err.statusType = err.status;
                  err.status = "error";
                  err.message = err.response.data.message;
                  err.messageError = err.message
                }
                this.doSetAlertForm(err);
              })
          }
        })
      },
      // TEAM END

      // GUARANTEE START
      apiGetGuarantee: function () {
        if (this.pageSlug) this.loadingOverlay = true
        this.data.data = false
        Gen.apiRest(
          "/get/" + "Guarantee"
        ).
        then(res => {
            this.loadingOverlay = false
            this.row_guarantee = res.data.row_g;
          })
          .catch(() => {
            this.loadingOverlay = false
          })
      },
      doSubmitGuarantee(idFormGuarantee = 'VForm6') {
        this.row_guarantee.sw_update_by = this.user.id;
        return this.doSubmit(
          "/do/" + this.modulePage,
          _.assign({
            type: 'updateGuarantee',
            id: this.row_guarantee.sw_id
          }, this.row_guarantee),
          (type, resp) => {
            if (type == 'success') {
              this.apiGetGuarantee();
            } else {
              if (resp.response.status == 422) {
                if ((_.values(resp.response.data)[0][0] || "").indexOf('validation') > -1) {
                  var msg = ""
                  $.each(Object.keys(resp.response.data), (i, v) => {
                    msg = msg + " <br/>" + v + " : " + _.values(resp.response.data[v]);
                  })
                  return global.Swal.fire("Blocked With Validation!", msg)
                } else {
                  return global.Swal.fire("Sorry, There is something wrong!", _.values(resp.response.data)[0][0])
                }
              } else if (resp.response.status == 400) {
                return global.Swal.fire({
                  title: resp.response.data.message,
                  icon: 'error',
                  confirmButtonText: 'Ok'
                })
              }
            }
          },
          'POST', idFormGuarantee
        )
      },
      // GUARANTEE END

      endDrag() {
        const page = this.$route.query.page || 1
        Gen.apiRest(
          "/do/" + 'OurTeams', {
            data: {
              type: 'sort',
              data: this.data_teams.data,
              page: page
            }
          },
          "POST"
        )
      },
      endDrag2() {
        Gen.apiRest(
          "/do/" + 'OurValues', {
            data: {
              type: 'sort',
              data: this.data_values.data
            }
          },
          "POST"
        )
      },

      // Coverage

      doDeleteCV(id) {
        global.Swal.fire({
          title: 'Are you sure to delete?',
          text: "You won't be able to restore this data!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonColor: '#3085d6',
          confirmButtonText: 'Yes, delete it!',
          customClass: {
            confirmButton: 'btn btn-custom',
            cancelButton: 'btn btn-outline-custom ml-1',
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            this.loadingOverlay = true
            this.openForm = false;
            Gen.apiRest("/do/" + 'Coverage', {
                data: {
                  type: 'delete',
                  id: id
                }
              }, "POST")
              .then(() => {
                this.apiGetCoverage();
                this.clickMap = true;
                document.querySelector("#addCity .map_indo").style.cursor = 'default';
                this.isModalOpenCV = false;
                global.Swal.fire({
                  title: 'Coverage Deleted Successfully!',
                  icon: 'success',
                })
              })
              .catch((err) => {
                this.loadingOverlay = false
                if (err) {
                  err.statusType = err.status;
                  err.status = "error";
                  err.message = err.response.data.message;
                  err.messageError = err.message
                }
                this.doSetAlertForm(err);
              })
          }
        })
      },


      apiGetCoverage() {

        if (this.pageSlug) this.loadingOverlay = true
        this.data.data = false
        Gen.apiRest(
          "/get/" + "Coverage"
        ).
        then(res => {
            this.loadingOverlay = false
            this.data_CV = res.data.data;
          })
          .catch(() => {
            this.loadingOverlay = false
          })
      },
      setModalCV(i) {
        this.isModalOpenCV = true
        
        if (i == undefined) {
          this.isAddCV = true;
          this.dataModalCV = {}
        } else {
          this.isAddCV = false;
          Gen.apiRest(
            "/get/" + "Coverage/" + this.data_CV.data[i].ac_id
          ).
          then(res => {
              this.loadingOverlay = false
              this.dataModalCV = res.data.row_cv;
              if(this.dataModalCV.ac_address[0] === "All City"){
                this.coverCity = "Y"
              }
              else{
                this.coverCity = "N"
              }
            })
            .catch(() => {
              this.loadingOverlay = false
            })
        }
      },
      doSubmitCV(IDFormCV = 'VForm7') {
        this.dataModalCV.ac_is_active = "Y";

        if(this.coverCity === "Y"){
          this.dataModalCV.ac_address = ["All City"]
        }

        if (!this.isAddCV) {
          this.dataModalCV.ac_update_by = this.user.id;
        } else {
          this.dataModalCV.ac_create_by = this.user.id;

          this.dataModalCV.ac_top = this.topPercent;
          this.dataModalCV.ac_left = this.leftPercent;
          this.dataModalCV.ac_create_by = this.user.id;
        }

        return this.doSubmit(
          "/do/" + "Coverage",
          _.assign({
            type: this.isAddCV ? 'add' : 'update'
          }, {
            id: this.dataModalCV.ac_id
          }, _.clone(this.dataModalCV)),
          (type, resp) => {
            if (type == 'success') {
              this.clickMap = true;
              document.querySelector("#addCity .map_indo").style.cursor = 'default';
              this.apiGetCoverage()
              $(".NMpas").remove();
              this.isModalOpenCV = false
            } else {
              if (resp.response.status == 422) {
                if ((_.values(resp.response.data)[0][0] || "").indexOf('validation') > -1) {
                  var msg = ""
                  $.each(Object.keys(resp.response.data), (i, v) => {
                    msg = msg + " <br/>" + v + " : " + _.values(resp.response.data[v]);
                  })
                  return global.Swal.fire("Blocked With Validation!", msg)
                } else {
                  return global.Swal.fire("Sorry, There is something wrong!", _.values(resp.response.data)[0][0])
                }
              } else if (resp.response.status == 400) {
                return global.Swal.fire({
                  title: resp.response.data.message,
                  icon: 'error',
                  confirmButtonText: 'Ok'
                })
              }
            }
          },
          'POST', IDFormCV, this.isAddCV
        )
      },
      doReset(){
        setTimeout(() => {
          this.rowSEO = {...this.rowSEOtemp}
          $('#button-cancel').attr('disabled', 'disabled')
        }, 0)
      },
      checkDisabled(){
        $('#button-cancel').removeAttr('disabled')
      },
      doPaginateTeam(page = 1){
        this.$set(this.filter, "page", page);
        this.$router.push({name:this.$route.name,query:_.clone(this.filter)})
        this.loadingOverlay = true
        setTimeout(() => {
          this.loadingOverlay = false
          document.querySelector('#card-team').scrollIntoView({behavior: "smooth", block: 'nearest'})
        }, 300)
      },

      changeTab(tab){
        this.tab = tab
        if(this.tab === "SEO"){
          let confSeo = JSON.stringify({ menu : `${this.$route.name}`, val : "Y"})
          localStorage.setItem("SEO", confSeo)
        }else{
          let confSeo = JSON.stringify({ menu : `${this.$route.name}`, val : "N"})
          localStorage.setItem("SEO", confSeo)
        }
      },
    },


    mounted() {
      if(this.TabSeo !== null && this.TabSeo.menu === this.$route.name && this.TabSeo.val === "Y"){
        this.tab = 'SEO'
        setTimeout(()=>{
          $("#editor").removeClass("active").removeClass("show");
          $("#seo").addClass("active").addClass("show");

          $("#main-nav").removeClass("active");
          $("#seo-nav").addClass("active");
        },400)
      }

      this.apiGet();
      this.apiGetHeroImage();
      this.apiGetSEO();
      this.apiGetVisMis();
      this.apiGetOurValues();
      this.apiGetOurTeams();
      this.apiGetGuarantee();
      this.apiGetCoverage();
      this.getPointClick();
      setTimeout(() => {
        this.doReset();
      }, 300)

      $(".vs__open-indicator").attr("type", "button")
    },
    watch: {
      $route() {
        setTimeout(() => {
          this.apiGet();
          this.apiGetHeroImage();
          this.apiGetSEO();
          this.apiGetVisMis();
          setTimeout(() => {
            this.doReset();
          }, 300)


        }, 500)
        
        $(".vs__open-indicator").attr("type", "button")
      },
      'row_hero.ahi_title'(v){
        this.row_hero.ahi_alt = v
      },
      'row_guarantee.sw_title'(v){
        this.row_guarantee.sw_alt = v
      },
      'dataModalTeams.at_name'(v){
        this.dataModalTeams.at_alt = v
      }

    }
  }

</script>